
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
import { ReguaCobrancaService } from '@/core/services/regua';
import {  CentroCustoService, ContaCorrenteService, ContaGerencialService} from "@/core/services/financeiro";


@Component
export default class FluxoCaixa extends mixins(Vue, ListPage) {
  filter: any = new Model();

  service = new RelatorioService()
  serviceRegua = new ReguaCobrancaService();
  etapaModel = new Etapas()
   listaCentroCusto = [];
  listaContaGerencial = [];
  contaCorrentes = [];

  lista: any = [];

  headers = [
    {text: 'Dessert (100g serving)', align: 'start', sortable: false, value: 'name'},
    { text: 'Calories', value: 'calories' },
    { text: 'Fat (g)', value: 'fat' },
    { text: 'Carbs (g)', value: 'carbs' },
    { text: 'Protein (g)', value: 'protein' },
    { text: 'Iron (%)', value: 'iron' },
  ]

  breadCrumbLinks: any[] = [
    { text: 'Home',href: '/financeiro/painel/kpi'},
    { text: 'Painel de Inadimplência',disabled: true,href: '/financeiro/painel/fluxocaixa'}
  ];

  titulo: string = 'Painel de cobrança';
  subTitulo: string = 'tipos de bancos cadastrados no Sistema';

  options: any = {
    itemsPerPage: 15
  };

  contratosCanceladosGraphs: any[] = [
    {
      1: { text: 'JAN', value: 12 },
      2: { text: 'FEV', value: 12 }, 
      3: { text: 'MAR', value: 13 },
    }
  ]

  ObterRegua() {
    if(this.filter.id > 0){
      this.service.ObterRelatorioPainelInadimplencia(this.filter.id).then(
        (res) => {
          this.etapaModel = res.data;
          this.etapaModel.etapas = res.data.etapas
        },
      )
    }
  }

  @Watch("options", { deep: true })
  Atualizar() {
     this.serviceRegua.Listar(-1, -1, [], [], this.search, [], undefined, 'Etapas').then(
      res => {
        this.lista = res.data.items;
        this.filter.id = this.lista[0].id
        this.ObterRegua();
      },
    )
  }

  desserts= [
          {
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: 1,
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: 1,
          },
          {
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: 7,
          },
          {
            name: 'Cupcake',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: 8,
          },
  ]


  get items() {
      let items:any = [];

      for (let i = 1; i < 10; i++) {
        let children1:any = [];
        // Children
        for (let j = 1; j < 5; j++) {
          let children2:any = [];
          // grandchildren
          for (let k = 1; k < 5; k++) {
            children2.push({
              id: i + "-" + j + "-" + k,
              title: "grand-child-" + k,
              value1: "grand-child-value-1",
              value2: "grand-child-value-2",
              value3: "grand-child-value-3"
            });
          }

          children1.push({
            id: i + "-" + j,
            title: "child-" + j,
            value1: "child-value-1",
            value2: "child-value-2",
            value3: "child-value-3",
            children: children2
          });
        }
        // Root
        items.push({
          id: i,
          title: "title-" + i,
          value1: "value-1",
          value2: "value-2",
          value3: "value-3",
          children: children1
        });
      }

      return items;
    }
 mounted(){ 
  this.items();

  this.Atualizar();
  
    new ContaCorrenteService().Listar(-1, -1, ['nome'],[],'',[], '', '' , 'Id, nome', '').then(res =>{
      this.contaCorrentes = res.data.items
    })

    new CentroCustoService().Listagem().then((res) => {
      this.listaCentroCusto = res.data;
    });

    new ContaGerencialService().Listagem().then((res) => {
      this.listaContaGerencial = res.data;
    });
  }
}

class Model {
  id = null;
}

class Etapas {
  etapas: any = [];
  totalParcelas: number = 0;
  valorTotal: number = 0;
}
